import React from 'react';
import { Avatar, Grid, Stack, Typography } from '@mui/material';
import Slider from 'react-slick';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PropTypes from 'prop-types';

import { useDevice } from '../../hooks/useResponsive';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Testimonial() {
  const isMobile = useDevice()?.isMobile;
  const testimonialDatas = [
    {
      img: '/assets/images/common/user.png',
      words: `I am truly grateful for my experience with IB Club. As an investor, the opportunities provided by IB Club have been nothing short of remarkable. The diverse membership options cater to individual needs, and the comprehensive support ecosystem ensures a seamless journey.`,
      name: 'Muhammed Rashid',
      designation: 'Jr.Developer, Osperb',
    },
    {
      img: '/assets/images/common/user.png',
      words: `I am truly grateful for my experience with IB Club. As an investor, the opportunities provided by IB Club have been nothing short of remarkable. The diverse membership options cater to individual needs, and the comprehensive support ecosystem ensures a seamless journey.`,
      name: 'Muhammed Rashid',
      designation: 'Jr.Developer, Osperb',
    },
    {
      img: '/assets/images/common/user.png',
      words: `I am truly grateful for my experience with IB Club. As an investor, the opportunities provided by IB Club have been nothing short of remarkable. The diverse membership options cater to individual needs, and the comprehensive support ecosystem ensures a seamless journey.`,
      name: 'Muhammed Rashid',
      designation: 'Jr.Developer, Osperb',
    },

    {
      img: '/assets/images/common/user.png',
      words: `I am truly grateful for my experience with IB Club. As an investor, the opportunities provided by IB Club have been nothing short of remarkable. The diverse membership options cater to individual needs, and the comprehensive support ecosystem ensures a seamless journey.`,
      name: 'Muhammed Rashid',
      designation: 'Jr.Developer, Osperb',
    },
    {
      img: '/assets/images/common/user.png',
      words: `I am truly grateful for my experience with IB Club. As an investor, the opportunities provided by IB Club have been nothing short of remarkable. The diverse membership options cater to individual needs, and the comprehensive support ecosystem ensures a seamless journey.`,
      name: 'Muhammed Rashid',
      designation: 'Jr.Developer, Osperb',
    },
    {
      img: '/assets/images/common/user.png',
      words: `I am truly grateful for my experience with IB Club. As an investor, the opportunities provided by IB Club have been nothing short of remarkable. The diverse membership options cater to individual needs, and the comprehensive support ecosystem ensures a seamless journey.`,
      name: 'Muhammed Rashid',
      designation: 'Jr.Developer, Osperb',
    },
  ];

  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        className="arrow arrow-left LeftArrow"
        role="button"
        tabIndex="0"
        onClick={onClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter') onClick();
        }}
      >
        <ChevronLeftIcon />
      </div>
    );
  };
  CustomPrevArrow.propTypes = {
    onClick: PropTypes.any,
  };
  // Custom right arrow component

  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        className="arrow arrow-right RighrArrow"
        role="button"
        tabIndex="0"
        onClick={onClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter') onClick();
        }}
      >
        <ChevronRightIcon />
      </div>
    );
  };
  CustomNextArrow.propTypes = {
    onClick: PropTypes.any,
  };

  const carouselSettings = {
    dots: false,
    infinite: true,
    dotsClass: 'slick-dots',
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    prevArrow: <CustomPrevArrow />, // Custom left arrow component
    nextArrow: <CustomNextArrow />, // Custom right arrow component
  };
  const carouselSettingsMobile = {
    height: 100,
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };
  return (
    <div style={{ padding: isMobile ? 15 : 25, marginBottom: '80px' }}>
      <Grid
        display={'flex'}
        alignItems={isMobile ? ' start' : ''}
        justifyContent={isMobile ? 'start' : 'center'}
        p={isMobile ? 2 : 6}
        mt={4}
        mx={1.5}
      >
        <Typography className="subtitle-1">THE WORDS</Typography>
      </Grid>
      {isMobile ? (
        <Slider {...carouselSettingsMobile}>
          {/* Carousel Slides */}
          {testimonialDatas?.map((item, key) => (
            <Stack key={key} className="" pt={4} pb={10}>
              {/* Slide 1 */}
              <Grid className="testimonial" mx={1} position={'relative'}>
                <img
                  src={`/assets/images/Home/uppercomma.png`}
                  alt=""
                  height={40}
                  width={51}
                  style={{ position: 'absolute', top: -25, left: 245 }}
                />
                <Typography className="testimonial-text">{item?.words}</Typography>
                <Stack
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  position={'relative'}
                  bottom={-35}
                >
                  <Avatar src={item?.img} alt="" className="" sx={{ height: 90, width: 90 }} />
                  <Stack textAlign={'center'}>
                    <Typography className="testimonial-username">{item?.name}</Typography>
                    <Typography className="testimonial-designation">{item?.designation}</Typography>
                  </Stack>
                </Stack>
              </Grid>
            </Stack>
          ))}
        </Slider>
      ) : (
        <Slider {...carouselSettings}>
          {testimonialDatas?.map((item, key) => (
            <Stack key={key} position={'relative'} pt={4.5} pb={10}>
              <Grid
                className="testimonial"
                mx={1}
                sx={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                <img
                  src={`/assets/images/Home/uppercomma.png`}
                  alt=""
                  height={50}
                  width={61}
                  style={{ position: 'absolute', top: -35, left: 280 }}
                />
                <Typography className="testimonial-text" px={3}>
                  {item?.words}
                </Typography>
              </Grid>
              <Stack
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                position={'relative'}
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  width: '100%',
                }}
              >
                <Avatar src={item?.img} alt="" className="" sx={{ height: 90, width: 90 }} />
                <Stack textAlign={'center'}>
                  <Typography className="testimonial-username">{item?.name}</Typography>
                  <Typography className="testimonial-designation">{item?.designation}</Typography>
                </Stack>
              </Stack>
            </Stack>
          ))}
        </Slider>
      )}
      {/* <SwipeableTextMobileStepper /> */}
    </div>
  );
}

export default Testimonial;
