import React from 'react';
import { Grid, Stack } from '@mui/material';

import ClubActivity from '../../Home-page/ClubActivity';
import { MetaHelmet } from '../../../layouts/Helmet/Helmet';
import Texts from './texts';
import AccordionPage from '../../Home-page/Accordion';
import BoardMember from './BoardMember';
import Policy from './Policy';
import Testimonial from '../Testimonial';
import { useDevice } from '../../../hooks/useResponsive';
import Shadow from '../../../components/Shadow';

export default function AboutUs() {
  const isMobile = useDevice()?.isMobile;

  return (
    <>
      {/* <MetaHelmet title={'About Us '} />
      {isMobile ? (
        <>
          <Stack sx={{ backgroundColor: '#FFFFFF' }}>
            <Texts />
            <AccordionPage />
            <BoardMember />
            <Policy />
            <ClubActivity />
            <Testimonial />
          </Stack>
        </>
      ) : (
        <Grid style={{ padding: isMobile ? 0 : 25, backgroundColor: '#FFFFFF' }}>
          <Container>
            <Texts />
            <AccordionPage />
            <BoardMember />
            <Policy />
            <ClubActivity />
            <Testimonial />
          </Container>
        </Grid>
      )}
    </> */}

      <MetaHelmet title={'About Us '} />
      <Stack sx={{ backgroundColor: '#FFFFFF' }}>
        {isMobile ? (
          <>
            <Stack px={2} zIndex={2}>
              <Texts />
              <AccordionPage type="noShadow" />
              <BoardMember />
              <Policy />
              <ClubActivity />
            </Stack>

            <Shadow
              type="right"
              style={{
                top: '60%',
              }}
            />
            <Shadow
              type="left"
              style={{
                top: '200%',
              }}
            />
            <Shadow
              type="left"
              style={{
                top: '340%',
              }}
            />
            <Testimonial />
          </>
        ) : (
          <>
            <Stack px={isMobile ? 0 : 17}>
              <Texts />
              <AccordionPage type="about" />
              <BoardMember />
              <Policy />
              <ClubActivity />
            </Stack>
            {/* // </Container> */}
            <Grid px={10}>
              <Testimonial />
            </Grid>
          </>
        )}
      </Stack>
    </>
  );
}
