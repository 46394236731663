import React from 'react';
import { Avatar, Box, Grid, Stack, Typography } from '@mui/material';
import { useDevice } from '../../../hooks/useResponsive';

function BoardMember() {
  const isMobile = useDevice()?.isMobile;
  const BoardMembers = [
    { img: '/assets/images/common/user1.png', name: 'Sayyid Sabiq Ali Shihab Thangal', des: 'CHAIRMAN' },
    { img: '/assets/images/common/user2.png', name: 'Usthad Hamza Faizy Haithamy', des: 'Advisory Director' },
    { img: '/assets/images/common/user3.png', name: 'Muhammed  Salaudheen Yamani', des: 'Managing Director' },
    { img: '/assets/images/common/user4.png', name: 'Hadif Sharafi', des: 'Financial Director' },
    { img: '/assets/images/common/user5.png', name: 'Shaheer Ahmad', des: 'Finance Advisory Director' },
    { img: '/assets/images/common/user6.png', name: 'Shafeeq Kizhisseri', des: 'Technical Director' },
    { img: '/assets/images/common/user7.png', name: 'Suhail Kidangayam', des: 'Operations Director' },
  ];
  return (
    <div>
      <Stack>
        <Typography className="direct-board" mb={3}>
          BOARD OF DIRECTORS
        </Typography>
      </Stack>
      {!isMobile ? (
        <Box mb={20} mt={4}>
          <Grid display={'flex'} alignItems={'center'} justifyContent={'center'} maxWidth={5000} px={0} py={3}>
            {BoardMembers?.slice(0, 4)?.map((item, key) => (
              <Grid key={key} display={''} alignItems={''} justifyContent={''}>
                <Stack display={'flex'} alignItems={'center'} justifyContent={'center'}>
                  <Avatar alt="User" src={item?.img} sx={{ height: 100, width: 100, objectFit: 'contain' }} />
                </Stack>
                <Stack display={'flex'} alignItems={'center'} justifyContent={'center'}>
                  <Typography className="member-title" p={2} textAlign={'center'}>
                    {item?.name}
                  </Typography>
                  <Typography className="member-designation">{item?.des}</Typography>
                </Stack>
              </Grid>
            ))}
          </Grid>
          <Grid display={'flex'} alignItems={'center'} justifyContent={'center'} maxWidth={5000} py={3}>
            {BoardMembers?.slice(4, 7)?.map((item, key) => (
              <Grid key={key} display={''} alignItems={''} justifyContent={''}>
                <Stack display={'flex'} alignItems={'center'} justifyContent={'center'}>
                  <Avatar alt="User" src={item?.img} sx={{ height: 100, width: 100, objectFit: 'cover' }} />
                </Stack>
                <Stack display={'flex'} alignItems={'center'} justifyContent={'center'} px={0}>
                  <Typography className="member-title-2" px={5} textAlign={'center'}>
                    {item?.name}
                  </Typography>
                  <Typography className="member-designation" px={5}>
                    {item?.des}
                  </Typography>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : (
        <Stack sx={{ p: 2 }}>
          <Grid container spacing={5}>
            {BoardMembers?.map((item, key) => (
              <Grid item xs={6} key={key}>
                <Stack display={'flex'} alignItems={'center'} justifyContent={'center'}>
                  <Avatar alt="User" src={item?.img} sx={{ height: 100, width: 100, objectFit: 'cover' }} />
                </Stack>
                <Stack display={'flex'} alignItems={'center'} justifyContent={'center'} px={0}>
                  <Typography className="member-title" textAlign={'center'}>
                    {item?.name}
                  </Typography>
                  <Typography className="member-designation" mx={0}>
                    {item?.des}
                  </Typography>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Stack>
      )}
    </div>
  );
}

export default BoardMember;
