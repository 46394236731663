import { Grid, Stack } from '@mui/material';
import ClubActivity from '../Home-page/ClubActivity';
import { MetaHelmet } from '../../layouts/Helmet/Helmet';
import Projects from './AllProjects';
import Testimonial from '../Other/Testimonial';
import { useDevice } from '../../hooks/useResponsive';
import Shadow from '../../components/Shadow';

export default function AllProjects() {
  const isMobile = useDevice()?.isMobile;

  return (
    <>
      <MetaHelmet title={'Projects'} />

      <Stack sx={{ backgroundColor: '#FFFFFF', pt: isMobile ? 2 : 10 }}>
        {isMobile ? (
          <div style={{ position: 'relative' }}>
            <Shadow
              type="left"
              style={{
                bottom: '-5rem',
                width: '200px',
              }}
              isBottom
            />
            <Projects />
            <ClubActivity />
            <Testimonial />
          </div>
        ) : (
          <>
            <Stack px={17}>
              <Projects />
              <ClubActivity />
            </Stack>
            <Grid px={10}>
              <Testimonial />
            </Grid>
          </>
        )}
      </Stack>
    </>
  );
}
